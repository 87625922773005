$black: #111;
$lightBlack: #333;
$white: #FFF;
$offWhile: #e9ecef;
$deepPink: deeppink;
$orange: #f96816;
$blue: #007bff;
$deepSkyBlue: deepskyblue;
$purple: purple;
$green: #28a745;
$cyan: #17a2b8;
$red: #dc3545;
$deepRed: #bd2130;

$topbar-bg-color: $lightBlack;
$sidebar-bg-color: $black;
$footer-bg-color: $purple;
$toogle-btn-bg-color: $deepPink;
$page-content-bg-color: azure;