#sns a {
	font-size: 4em;
}

#sns a.text-twitter {
	color: rgb(29, 161, 242);
}

#sns a.text-fb {
	color: #4267b2;
}

