@media (max-width: 768px) {

    #smallDeviceDetector{
        display: none;
    }

    // .leftSidebar {
    //     width: 0px;
    // }

    // .footer, #pageContent{
    //     margin-left: 0px;
    // }
}
