// Breakpoints
$large-breakpoint: 64em !default;
$medium-breakpoint: 42em !default;

// Headers
$header-heading-color: #fff !default;
$header-bg-color: #159957 !default;
$header-bg-color-secondary: #155799 !default;

// Text
$section-headings-color: #159957 !default;
$body-text-color: #606c71 !default;
$body-link-color: #1e6bb8 !default;
$blockquote-text-color: #819198 !default;

// Code
$code-bg-color: #f3f6fa !default;
$code-text-color: #567482 !default;

// Borders
$border-color: #dce6f0 !default;
$table-border-color: #e9ebec !default;
$hr-border-color: #eff0f1 !default;
