#pageContent {
    padding: 1rem;
    border: 1px solid #e1e4e8;
    box-sizing: border-box;
	max-width: 90vw;
}




