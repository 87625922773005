@import "dims";

.mainContent{
    margin: 0px;
    margin-left: $sidebarWidth;
    padding: 0px;
    background-color: $white;
    color: #24292e;
	width: 100vw;
	word-break: break-all;
}

