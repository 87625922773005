@import "dims";
@import "colors";

.footer{
    padding: $footerPadding;
    margin: 0px;
    //margin-left: 250px;
    background: $footer-bg-color;
    color: $white;
    //max-height: $footerHeight;
}

#copyright{
    color: $white;
}

#animatedInfo{
    float: right;
}