@import "colors";
@import "dims";

.sidebar{
  margin: 0px;
  padding: 0px;
}

#sidebar {
    height: 100%;
    width: $sidebarWidth;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: #111;
    transition: 0.5s;
    overflow-y: scroll;
}
  
#sidebar .sidebarTop{
    background-color: #111;
}

/*
.sidebar a {
//padding: 8px 8px 8px 32px;
text-decoration: none;
//font-size: 25px;
color: #818181;
display: block;
transition: 0.3s;
}


.sidebar a:hover {
color: #f1f1f1;
}
*/
  
	.sidebar a {
	  //border-bottom: 1px solid $orange;
	  color: #818181;
	  padding-bottom: .25em;
	  text-decoration: none;
	}

	.sidebar a:hover {
	  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23ff9800' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
	  background-position: bottom;
	  background-repeat: repeat-x;
	  background-size: auto 4px;
	  border-bottom: 0;
	  padding-bottom: .3em;
	  text-decoration: none;
	  color: #f1f1f1;
	}

/*
  .sidebarTop, .hoverInfo{
    background: $lightBlack;
  }
*/

.logo {
    display: block;
    margin-left: auto;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    background: $deepSkyBlue;
}

.hoverInfo button {
  display: block;
  margin-left: auto;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
}

.btn-group-lg>.btn, .btn-lg {
  border-radius: 0px;
}

/* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
*::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul.timeline {
  border-left: 4px solid $orange;
  font-family: 'Source Sans Pro', sans-serif;
  margin-left: 8px;
  position: relative;
  line-height: 1.4em;
  padding-left: 0px;
  list-style: none;
  text-align: left;
  margin-bottom: 0px;
}

ul.timeline li {
  list-style-type: none;
  position: relative;
  margin: 0 auto;
  padding: 10px 2px 10px 10px;
}

ul.timeline li::before {
  content: '';
  position: absolute;
  background: $orange;
  left: -12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-color: $sidebar-bg-color;
  border-width: medium;
  border-style: solid;
}

/* Toggle
------------------------------------------------------------ */
#sidebarToggle {

}


/* Nav Link
------------------------------------------------------------ */
.sidebar .nav-link {
    padding: .5rem;
}


/* breadcrumb adjustment */
/*
#breadcrumb {
	margin-top:  -6.5px;
}
*/