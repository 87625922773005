@import "colors";

body {
    font-family: 'Meiryo UI', 'Roboto', 'Segoe UI', 'Tahoma', sans-serif;
    background: $white;
}

p {
    /*font-family: 'Roboto';*/
    font-size: 1.1em;
    /*font-weight: 300;*/
    /*line-height: 1.7em;*/
    color: #24292e;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.leftSidebar, .mainContent, .footer {
    transition: 0.5s;
}


/*
 ------------------ Scroll bar ------------------------
*/

/*
::-webkit-scrollbar {
    width: 0 !important;
    display: none;
}
.mainContent, .leftSidebar { 
    overflow: -moz-scrollbars-none; 
    -ms-overflow-style: none;
}
*/

/* The emerging W3C standard that is currently Firefox-only */
* {
  scrollbar-width: auto;
  scrollbar-color: #333 #ddd;
}


/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: auto;
}

*::-webkit-scrollbar-track {
  background: #ddd;
}

*::-webkit-scrollbar-thumb {
  background-color: #333;
}

aside { 
    overflow: -moz-scrollbars-none; 
    -ms-overflow-style: none;
}

aside::-webkit-scrollbar {
    width: 0 !important;
    display: none;
}

/*
 ------------------ END: Scroll bar ------------------------
*/

.pageContent{
    background: $white;
}

#pageContent h1, #pageContent h2 {
    padding-bottom: .3em;
    border-bottom: 1px solid #eaecef;
}

#pageContent h1, #pageContent h2, #pageContent h3, #pageContent h4 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
}

#pageContent a {
    /*font-style: italic;*/
    color: deepskyblue;
    text-decoration: underline;
	/*line-height: 1.7em;*/
}

#pageContent div.page-author-info h6 {
    font-size: medium;
}
div.page-author-info .badge {
    font-size: 100%;
}

pre.highlight {
	font-size: 0.8em;
}


/* code padding */
pre {
    padding: 1em;
}

img {
    margin: 0;
}

/* style for details element */
details {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: .5em .5em 0;
}

summary {
    font-weight: bold;
    margin: -.5em -.5em 0;
    padding: .5em;
}

details[open] {
    padding: 1em;
}

details[open] summary {
    /*border-bottom: 1px solid #aaa;*/
    margin-bottom: .5em;
}

.badge-90p{
    font-size: 90%;
}

blockquote {
    background-color: azure;
}


.dropdown-item:focus, .dropdown-item:hover {
    color: #000;
    text-decoration: none;
    background-color: #ffc107;
}

/*
 ------------------ h1, h2 falls behind topbar fix------------------------
*/
:target {
    scroll-margin-top: 4rem;
}