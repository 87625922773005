@import url('https://fonts.googleapis.com/css?family=Lato|Roboto&display=swap');

@import 'jekyll-theme-cayman';



/* ---------------------------------------------------
    COMMON STYLE
----------------------------------------------------- */
@import 'common';



/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
@import 'sidebar';



/* ---------------------------------------------------
    TOPBAR STYLE
----------------------------------------------------- */
//@import 'topbar';



/* ---------------------------------------------------
    MAIN CONTENT STYLE
----------------------------------------------------- */
@import 'main-content';



/* ---------------------------------------------------
    PAGE CONTENT STYLE
----------------------------------------------------- */
@import 'page-content';



/* ---------------------------------------------------
    FOOTER STYLE
----------------------------------------------------- */
@import 'footer';



/* ---------------------------------------------------
    MEDIA QUERIES
----------------------------------------------------- */
@import 'mobile';



/* ---------------------------------------------------
    SNS
----------------------------------------------------- */
@import 'sns';



/* ---------------------------------------------------
    CODE
----------------------------------------------------- */
/*
pre.highlight {
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;
    margin-bottom: 0;
    word-break: normal;
    padding: 1rem;
}
@import 'github-like-syntax';
*/
@import 'syntax';